<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="griLayOut"
      :grid-row-btn="gridRowBtn"
      :table-options="tableOPtions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
    >
      <!-- <template #decideDocument="{ row, index }">
        <el-link type="primary" @click="rowView(row)">{{
          row.decideDocument
        }}</el-link>
      </template> -->
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout.vue";

import {mapGetters} from "vuex";
import {deptChildTree} from "@/api/reportTemplate";
import {
  decideheadPage,
  decideLinePage,
} from "@/api/responsibility/grsafetydecide";

export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      searchColumns: [
        {
          prop: "decideDocument",
          placeholder: "文书名称",
          span: 4,
        },
      ],
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      query: {},
      userId: "",
    };
  },
  computed: {
    tableOPtions() {
      return {
        menu: false,
        selection: false,
        column: [
          {
            label: "奖惩文书名称",
            prop: "decideDocument",
            align: "center",
            overHidden: true,
            // slot: true,
          },
          {
            label: "奖惩单位",
            prop: "orgName",
            align: "center",
            overHidden: true,
          },
          {
            label: "接收对象",
            prop: "userName",
            align: "center",
            overHidden: true,
          },
          {
            label: "奖惩类型",
            prop: "decideType",
            align: "center",
            dataType: "number",
            overHidden: true,
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=decide_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "奖惩时间",
            prop: "decideDate",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },
  methods: {
    init(id) {
      this.userId = id;
      this.onLoad(this.page);
    },
    /** 右侧文书数据操作 */
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.userId = this.userId
      decideLinePage({
        current: page.currentPage,
        size: page.pageSize,
        ...params, ...this.query
      }).then((res) => {
        const data = res.data.data;
        // this.$refs.gridLayOut.page.total = data.total;
        this.page.total = data.total;
        // this.tableData = data.records
        //   ? data.records.map((val) => {
        //       val.decideType = val.decideType.toString();
        //       return val;
        //     })
        //   : [];
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page, this.$refs.gridHeadLayout.searchForm);
    },
    // 搜索
    gridHeadSearch(query) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = {...data, ...query};
      this.onLoad(this.page);
    },
    // 重置
    gridHeadEmpty(query) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = {...data, ...query};
      this.onLoad(this.page);
    },
  },
};
</script>
<style scoped lang="scss"></style>
