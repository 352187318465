var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        height: "100%",
        "background-color": "#fff",
        "padding-top": "12px",
      },
    },
    [
      _c("head-layout", {
        attrs: { "head-title": "", "head-btn-options": _vm.headBtnOptions },
        on: { "head-save": _vm.handleSave },
      }),
      _c(
        "div",
        { staticStyle: { "padding-top": "10px" } },
        [
          _c(
            "avue-form",
            {
              ref: "addForm",
              attrs: { option: _vm.formOptions },
              model: {
                value: _vm.addForm,
                callback: function ($$v) {
                  _vm.addForm = $$v
                },
                expression: "addForm",
              },
            },
            [
              _c("template", { slot: "birthday" }, [
                _vm.pageType == "relatedPersonnel"
                  ? _c("span", { style: _vm.itemObject(_vm.addForm) }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.addForm.idCoding && _vm.addForm.idCoding != ""
                              ? _vm.getAge(_vm.addForm.idCoding)
                              : ""
                          ) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm.pageType == "personnel"
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.addForm.birthday && _vm.addForm.birthday != ""
                              ? _vm.getAgeByBirthday(_vm.addForm.birthday)
                              : ""
                          ) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }