<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
    >
    </grid-layout>
  </div>
</template>

<script>
import CommonTree from "@/views/components/com_tree";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import {
  prjbasicinfoPageByUserId,
  prjbasicinfoPageByCompanyId,
} from "@/api/safe/itemList";

export default {
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      query: {},
      selectionList: [],
      tableData: [],
      tableLoading: true,

      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "项目名称",
          prop: "condition",
          span: 4,
          placeholder: "项目名称或编号",
        },
      ],
      pageId: "",
    };
  },
  computed: {
    tableOption() {
      return {
        selection: false,
        linklabel: "",
        menu: false,
        column: [
          {
            label: "项目编码",
            prop: "prjCode",
            align: "center",
            overHidden: true,
            minWidth: 200,
          },
          {
            label: "项目名称",
            prop: "prjName",
            align: "center",
            overHidden: true,
            minWidth: 200,
          },
          {
            label: "境内外",
            prop: "isDomestic",
            dataType: "number",
            align: "center",
            minWidth: 80,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_domestic`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "项目状态",
            prop: "prjState",
            align: "center",
            dataType: "number",
            minWidth: 80,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=prj_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "进出场",
            prop: "changeState",
            align: "center",
            dataType: "number",
            minWidth: 80,
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=eae_prj_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "进出场时间",
            prop: "changeDate",
            align: "center",
            minWidth: 150,
            overHidden: true,
          },

        ],
      };
    },
  },
  methods: {
    init(id, pageType) {
      this.pageId = id;
      this.pageType = pageType;
      this.onLoad(this.page, {});
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;

      let reqApi =
        this.pageType == "relatedOrg"
          ? prjbasicinfoPageByCompanyId
          : prjbasicinfoPageByUserId;
      if (this.pageType == "relatedOrg") {
        params.companyCode = this.pageId;
      } else {
        params.userId = this.pageId;
      }
      reqApi(params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data;
        }
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
  },
};
</script>
<style scoped lang="scss"></style>
