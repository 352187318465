var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: { "head-title": "", "head-btn-options": _vm.headBtnOptions },
        on: { "head-upload": _vm.handleUpload },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOptions,
          "grid-row-btn": _vm.gridRowBtn,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.handleCurrentChange,
          "page-size-change": _vm.handleSizeChange,
          "page-refresh-change": _vm.onLoad,
          "row-edit": _vm.rowEdit,
          "row-download": _vm.rowDownload,
          "row-remove": _vm.rowRemove,
        },
        scopedSlots: _vm._u([
          {
            key: "fileName",
            fn: function ({ row, index }) {
              return [
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.rowView(row, index)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.getFileName(row.fileName)))]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: { title: "证件查看", visible: _vm.imageDiavlog },
          on: {
            "update:visible": function ($event) {
              _vm.imageDiavlog = $event
            },
          },
        },
        [
          _vm.fileUrl.type == "pdf"
            ? _c("pdf", { attrs: { src: _vm.fileUrl.src } })
            : _c("el-image", { attrs: { src: _vm.fileUrl.src } }),
        ],
        1
      ),
      _c("cedForm", { ref: "cedForm", on: { callback: _vm.callback } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }