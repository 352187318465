<template>
  <div>
    <grid-head-layout
      ref="gridHeadLayout"
      :search-columns="searchColumns"
      @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"
    ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @page-refresh-change="onLoad"
    >
      <template #courseCover="{ row }">
        <img
          class="courseCoverImg"
          v-if="row.courseCover == ''"
          src="@/assets/training/trainingDefaultPicture.png"
        />
        <img class="courseCoverImg" v-else :src="row.courseCover" />
      </template>
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";

import * as API from "@/api/personnelHolography";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
      isCycle: "",
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: "培训名称",
          prop: "courseName",
          span: 4,
          placeholder: "请输入培训名称",
        },
      ],
      userId: "",
    };
  },
  computed: {
    tableOption() {
      return {
        selection: false,
        // linklabel: "partnerUserName",
        menu: false,
        column: [
          {
            label: "名称",
            prop: "courseName",
            align: "left",
            overHidden: true,
          },
          {
            label: "课程",
            prop: "courseCover",
            slot: true,
            width: 120,
          },
          {
            label: "课时",
            prop: "totalHours",
            align: "center",
            width: 80,
            overHidden: true,
          },
          {
            label: "状态",
            prop: "status",
            align: "center",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=education_status",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 80,
            dataType: "number",
            overHidden: true,
          },
          {
            label: "标签",
            prop: "label",
            align: "center",
            overHidden: true,
            width: 140,
          },
          {
            label: "达标课时",
            prop: "standardsHours",
            align: "center",
            width: 90,
            overHidden: true,
          },
          {
            label: "完成必修课时",
            prop: "accomplishAccomplish",
            align: "right",
            width: 130,
            overHidden: true,
          },
          {
            label: "是否完成",
            prop: "isAccomplish",
            align: "center",
            dicUrl:
              "/api/sinoma-system/dict-biz/dictionary?code=education_is_accomplish",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 90,
            dataType: "number",
            overHidden: true,
          },
          {
            label: "培训时间",
            prop: "trainingTimeRange",
            align: "center",
            width: 240,
            overHidden: true,
          },
        ],
      };
    },
  },
  methods: {
    init(id) {
      this.userId = id;
      this.onLoad(this.page);
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;

      params.userId = this.userId;
      API.personnelTrainRecord({current:page.currentPage, size:page.pageSize, ...params}).then(
        (res) => {
          if (res.data.code == 200) {
            this.tableLoading = false;
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            this.$refs.gridLayOut.page.total = res.data.data.total;
          }
        }
      );
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
  },
};
</script>
<style scoped lang="scss">
.courseCoverImg {
  width: 80px;
  padding: 5px;
}
</style>
