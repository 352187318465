<template>
  <div>
    <grid-head-layout ref="gridHeadLayout" :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut" :table-options="tableOption" :table-data="tableData" :table-loading="tableLoading"
      :data-total="page.total" :page="page" @page-current-change="onLoad" @page-size-change="onLoad"
      @page-refresh-change="onLoad">
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";

import * as API from "@/api/siteManagement/personEAExit";
import { getList } from "@/api/siteManagement/collaborativeEAExit";
export default {
  components: {
    GridLayout,
  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
      isCycle: "",
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        // {
        //   label: "人员姓名",
        //   prop: "userName",
        //   span: 4,
        //   placeholder: "请输入人员姓名",
        // },
        {
          label: "",
          prop: "startDate",
          span: 4,
          type: "date-format",
          placeholder: "行程日期",
        },
        // {
        //   label: "",
        //   prop: "tripState",
        //   span: 4,
        //   type: "select",
        //   placeholder: "状态",
        //   dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=trip_state`,
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey",
        //   },
        // },
      ],
      userId: "",
      pageType: "",
    };
  },
  computed: {
    tableOption() {
      return {
        selection: false,
        // linklabel: "partnerUserName",
        menu: false,
        column: [
          {
            label: "行程日期",
            prop: "startDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "出入境",
            prop: "tripState",
            align: "center",
            dataType: "number",
            dicUrl: `/api/sinoma-system/dict-biz/dictionary?code=trip_state`,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            overHidden: true,
          },
          {
            label: "出发地",
            prop: "startPlace",
            align: "center",
            overHidden: true,
          },
          {
            label: "目的地",
            prop: "endPlace",
            align: "center",
            overHidden: true,
          },
          {
            label: "归属项目",
            prop: "prjName",
            align: "center",
            overHidden: true,
          },
        ],
      };
    },
  },
  methods: {
    init(id, pageType) {
      this.pageType = pageType;
      this.userId = id;
      this.onLoad(this.page);
    },
    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;

      if(this.pageType=="personnel"){
        params.userId = this.userId;
      }else{
        params.partnerUserId = this.userId;
      }
      let reqApi = this.pageType == "personnel" ? API.getList : getList;

      reqApi(page.currentPage, page.pageSize, params).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 搜索
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.$refs.gridHeadLayout.searchForm = {};
      this.onLoad(this.page, searchForm);
    },
  },
};
</script>
<style scoped lang="scss"></style>
